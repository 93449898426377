<template>
  <form @submit.prevent="save()">
    <div class="row">
      <div class="col-12 col-xl-6">
        <div class="form-group">
          <label for="name">Name</label>
          <input
            type="string"
            class="form-control"
            name="name"
            v-model="form.name"
            required
          />
        </div>
      </div>
      <div class="col-12 col-xl-6">
        <div class="form-group">
          <label for="date">Datum</label>
          <input
            type="date"
            class="form-control"
            name="date"
            v-model="form.date"
            required
          />
        </div>
      </div>
      <div class="col-12 col-xl-6">
        <div class="form-group">
          <label for="payment">Honorar (€)</label>
          <input
            type="number"
            class="form-control"
            name="payment"
            v-model.number="form.payment"
            required
          />
        </div>
      </div>
      <div class="col-12 col-xl-6">
        <div class="form-group">
          <label for="trainer">Trainer</label>
          <select
            name="trainer"
            v-model="form.trainer"
            class="form-control"
            required
          >
            <option :value="null" disabled>Trainer auswählen...</option>
            <option
              :value="trainer"
              v-for="trainer in trainers"
              :key="trainer.id"
              >{{ trainer.displayName }}</option
            >
          </select>
        </div>
      </div>
      <div class="col-12 col-xl-6">
        <div class="form-group">
          <label for="holding">Holding</label>
          <select
            name="trainer"
            v-model="form.holding"
            class="form-control"
            required
          >
            <option :value="null" disabled>Holding auswählen...</option>
            <option
              :value="holding"
              v-for="holding in holdings"
              :key="holding.id"
              >{{ holding.name }}</option
            >
          </select>
        </div>
      </div>
    </div>
    <button type="submit" class="btn btn-dark" :disabled="submitting">
      Speichern
    </button>
    <div class="alert alert-danger mt-3" v-if="errMsg">{{ errMsg }}</div>
  </form>
</template>

<script>
import { format } from 'date-fns';

import { firestore } from '../firebase';

export default {
  data() {
    return {
      timeRecord: null,
      form: {
        name: '',
        date: format(Date.now(), 'yyyy-MM-dd'),
        trainer: null,
        payment: 0,
        holding: null,
      },
      trainers: [],
      holdings: [],
      submitting: false,
      errMsg: '',
    };
  },
  async created() {
    const id = this.$route.params.id;

    const trainersSnapshot = await firestore
      .collection('trainers')
      .orderBy('displayName')
      .get();

    this.trainers = trainersSnapshot.docs.map((snapshot) => {
      return {
        id: snapshot.id,
        ...snapshot.data(),
      };
    });

    const trainer = this.trainers.find(
      (trainer) => trainer.id === this.$route.params.trainerId
    );

    const holdingsSnapshot = await firestore.collection('holdings').get();

    this.holdings = holdingsSnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });

    this.form = {
      ...this.form,
      trainer,
    };

    if (id) {
      const timeRecordSnapshot = await firestore
        .collection('timeRecords')
        .doc(id)
        .get();

      this.timeRecord = {
        id: timeRecordSnapshot.id,
        ...timeRecordSnapshot.data(),
      };
      this.form = { ...this.timeRecord };

      delete this.form.id;
    }
  },
  methods: {
    async save() {
      this.submitting = true;
      this.errMsg = '';

      try {
        if (this.timeRecord) {
          await firestore
            .collection('timeRecords')
            .doc(this.timeRecord.id)
            .update({
              ...this.form,
            });
        } else {
          await firestore.collection('timeRecords').add({
            ...this.form,
          });
        }

        this.$router.push({
          name: 'time-records',
        });
      } catch (err) {
        this.errMsg = err.message;

        throw err;
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>
